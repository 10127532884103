/* ORDERS */
export const GET_BANKDETAILS = "GET_BANKDETAILS"
export const GET_BANKDETAILS_SUCCESS = "GET_BANKDETAILS_SUCCESS"
export const GET_BANKDETAILS_FAIL = "GET_BANKDETAILS_FAIL"

export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

export const GET_ORDERSITEMS = "GET_ORDERSITEMS"
export const GET_ORDERSITEMS_SUCCESS = "GET_ORDERSITEMS_SUCCESS"
export const GET_ORDERSITEMS_FAIL = "GET_ORDERSITEMS_FAIL"

export const GET_ORDERSITEMS_BYFILTERED = "GET_ORDERSITEMS_BYFILTERED"
export const GET_ORDERSITEMS_BYFILTERED_SUCCESS =
  "GET_ORDERSITEMS_BYFILTERED_SUCCESS"
export const GET_ORDERSITEMS_BYFILTERED_FAIL = "GET_ORDERSITEMS_BYFILTERED_FAIL"

/* ORDERS DETAIL*/
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL"
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS"
export const GET_ORDER_DETAIL_FAIL = "GET_ORDER_DETAIL_FAIL"

export const GET_ORDERSITEMS_DETAIL = "GET_ORDERSITEMS_DETAIL"
export const GET_ORDERSITEMS_DETAIL_SUCCESS = "GET_ORDERSITEMS_DETAIL_SUCCESS"
export const GET_ORDERSITEMS_DETAIL_FAIL = "GET_ORDERSITEMS_DETAIL_FAIL"

export const GET_ORDER_RAWMATERIAL = "GET_ORDER_RAWMATERIAL"
export const GET_ORDER_RAWMATERIAL_SUCCESS = "GET_ORDER_RAWMATERIAL_SUCCESS"
export const GET_ORDER_RAWMATERIAL_FAIL = "GET_ORDER_RAWMATERIAL_FAIL"
/**
 * add ORDER
 */
export const CREATE_ORDER = "CREATE_ORDER"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM"
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS"
export const UPDATE_ORDER_ITEM_FAIL = "UPDATE_ORDER_ITEM_FAIL"

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"

//Quotations

export const GET_QUOTATIONS = "GET_QUOTATIONS"
export const GET_QUOTATIONS_SUCCESS = "GET_QUOTATIONS_SUCCESS"
export const GET_QUOTATIONS_FAIL = "GET_QUOTATIONS_FAIL"

export const GET_QUOTATION_DETAIL = "GET_QUOTATION_DETAIL"
export const GET_QUOTATION_DETAIL_SUCCESS = "GET_QUOTATION_DETAIL_SUCCESS"
export const GET_QUOTATION_DETAIL_FAIL = "GET_QUOTATION_DETAIL_FAIL"

export const GET_QUOTATION_DUPLICATE = "GET_QUOTATION_DUPLICATE"
export const GET_QUOTATION_DUPLICATE_SUCCESS = "GET_QUOTATION_DUPLICATE_SUCCESS"
export const GET_QUOTATION_DUPLICATE_FAIL = "GET_QUOTATION_DUPLICATE_FAIL"

export const GET_QPRODUCTPRICE = "GET_QPRODUCTPRICE"
export const GET_QPRODUCTPRICE_SUCCESS = "GET_QPRODUCTPRICE_SUCCESS"
export const GET_QPRODUCTPRICE_FAIL = "GET_QPRODUCTPRICE_FAIL"

export const CREATE_QUOTATION = "CREATE_QUOTATION"
export const CREATE_QUOTATION_SUCCESS = "CREATE_QUOTATION_SUCCESS"
export const CREATE_QUOTATION_FAIL = "CREATE_QUOTATION_FAIL"

export const UPDATE_QUOTATION = "UPDATE_QUOTATION"
export const UPDATE_QUOTATION_SUCCESS = "UPDATE_QUOTATION_SUCCESS"
export const UPDATE_QUOTATION_FAIL = "UPDATE_QUOTATION_FAIL"

export const GET_QUOTATION_CLIENT_ID = "GET_QUOTATION_CLIENT_ID"
export const GET_QUOTATION_CLIENT_ID_SUCCESS = "GET_QUOTATION_CLIENT_ID_SUCCESS"
export const GET_QUOTATION_CLIENT_ID_FAIL = "GET_QUOTATION_CLIENT_ID_FAIL"
