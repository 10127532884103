import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Badge, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { getQuotationDuplicate, getQuotations } from "store/orders/actions"

import "../../assets/scss/datatables.scss"
import QuotationDetails from "./QuotationDetails"
import MyPagination from "components/Common/MyPagination"
import moment from "moment"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Quotation = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [searchText, setSearchText] = useState("")
  const [QDetails, setQDetails] = useState()
  const { quotation, loading } = useSelector(state => ({
    quotation: state.Orders.quotation,
    loading: state.Orders.quotationLoading,
  }))
  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  //pagination
  const [limit] = useState("10")
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("all")

  const totalPages = Math.ceil(quotation?.count / limit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getQuotations(searchText, page, finalDateFormated, sort))
  }, [dispatch, page, searchText, finalDateFormated, sort])

  const columns = [
    {
      dataField: "client_name",
      text: "Client",
      sort: true,
    },
    {
      dataField: "auto_id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "date_added",
      text: "Date",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const listChange = sortValue => {
    setSort(sortValue)
    console.log(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${page}&s=${sortValue}`,
      state: {
        page: page,
        sort: sortValue,
      },
    })
    // dispatch(getAllProducts(page, sortValue, totalItemLimit, ""))
  }

  const tableTitles = [
    {
      title: "All",
      value: "all",
    },

    {
      title: "Pending",
      value: "pending",
    },

    {
      title: "Approval",
      value: "approved",
    },

    {
      title: "Rejected",
      value: "rejected",
    },
  ]

  const Status = status => {
    console.log(status)
    if (status === "pending") {
      return "info"
    }
    if (status === "Pending") {
      return "info"
    }
    if (status === "approved") {
      return "success"
    }
    if (status === "rejected") {
      return "danger"
    }
    if (status === "duplicate") {
      return "warning"
    }
  }

  const handleDuplicate = quotationId => {
    dispatch(getQuotationDuplicate(quotationId))
    // dispatch(getQuotations("", "", "", ""))
  }

  // useEffect(() => {
  //   dispatch(getQuotationReport(finalDateFormated, sort))
  // }, [dispatch, finalDateFormated, sort])

  // const newQuotation = quotation?.results?.filter(
  //   item => item.status == "Pending"
  // )

  // const handlerQuot = () => {
  //   if (window.location.search) {
  //     return ""
  //   } else {
  //     return quotation?.results
  //   }
  // }

  const quotationData = map(quotation?.results, (item, index) => ({
    ...item,
    key: index,
    client_name: (
      <>
        <p>
          {item?.client_name} &nbsp;
          {item?.isDuplicate === true ? (
            <span className="text-warning">(copy)</span>
          ) : (
            ""
          )}
        </p>
      </>
    ),
    date_added: moment(item.date_added).format("YYYY/MM/DD"),
    status: (
      <div
        className="d-flex"
        style={{
          maxWidth: "120px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Badge
          style={{ textTransform: "capitalize" }}
          className={"font-size-12 badge-soft-" + `${Status(item?.status)}`}
          pill
        >
          {item.status}
        </Badge>
      </div>
    ),
    action: (
      <Link
        type="button"
        className="btn-sm btn-info btn-rounded"
        to="#"
        onClick={() => setQDetails(item.id)}
      >
        <i className="bx bx-show-alt font-size-16" title="View Details" />
        &nbsp; &nbsp;
        <i
          onClick={() => handleDuplicate(item?.id)}
          style={{ cursor: "pointer" }}
          className="bx bx-duplicate font-size-16 text-primary"
          title="Duplicate"
        />
      </Link>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  console.log(quotation?.results?.length)

  console.log(page)

  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <Card style={{ height: 120, borderRadius: 15 }}>
            <CardBody>
              <h5>Total Quote Count</h5>
              <h6>{quotation?.count || 0}</h6>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ height: 120, borderRadius: 15 }}>
            <CardBody>
              <h5>Total Quote Amount</h5>
              <h6 className="font-size-16 text-muted d-flex align-items-center justify-content-start">
                <i className="bx bx-rupee text-primary font-size-18" />{" "}
                {quotation?.total_value || 0}
              </h6>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg={`${QDetails ? "8" : "12"}`}>
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={quotationData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        className="btn-group mt-2 mt-xl-0 col-3 "
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        {tableTitles.map((head, key) => (
                          <Fragment key={key}>
                            <input
                              type="radio"
                              className="btn-check"
                              name="btnradio"
                              id={head.value + key}
                              autoComplete="off"
                              checked={sort === head.value}
                              onChange={() => listChange(head.value)}
                            />
                            <label
                              className="btn btn-success d-flex align-items-center w-100"
                              htmlFor={head.value + key}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              {head.title}
                            </label>
                          </Fragment>
                        ))}
                      </div>
                      <div>
                        <Link to={`/quotation/create`}>
                          <Button color="success">
                            {" "}
                            <i className="bx bx-plus" />
                            Create Quotation
                          </Button>
                        </Link>
                      </div>
                    </div>

                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search..."
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>{" "}
                          </div>
                        </div>
                      </Col>
                      <Col md="8">
                        <div className=" d-flex align-items-end justify-content-end mt-2">
                          <div className="d-flex sortWrapper mx-2 me-3 mb-3 align-items-center ">
                            <p
                              className="mb-0 pb-0 mx-1"
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Sort By Date :
                            </p>
                            <AvForm>
                              <div className="d-flex date_wrapper">
                                <div className="d-flex align-items-start position-relative">
                                  <AvField
                                    name="dateRange"
                                    type="date"
                                    validate={{
                                      dateRange: {
                                        start: { value: -5, units: "years" },
                                        end: { value: 5, units: "years" },
                                      },
                                    }}
                                    onChange={e =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        from: e.target.value,
                                      })
                                    }
                                    value={finalDateFormated.from}
                                  />
                                  <button
                                    onClick={() =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        from: "",
                                      })
                                    }
                                    type="button"
                                    className="btn btn-white editable-cancel date_close btn-sm"
                                  >
                                    <i className="mdi mdi-close text-danger "></i>
                                  </button>
                                </div>
                                <div className="d-flex align-items-start position-relative">
                                  <AvField
                                    name="dateRange"
                                    className="mx-1"
                                    type="date"
                                    validate={{
                                      dateRange: {
                                        start: { value: -5, units: "years" },
                                        end: { value: 5, units: "years" },
                                      },
                                    }}
                                    onChange={e =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        to: e.target.value,
                                      })
                                    }
                                    value={finalDateFormated.to}
                                  />
                                  <button
                                    onClick={() =>
                                      setFinalDateFormated({
                                        ...finalDateFormated,
                                        to: "",
                                      })
                                    }
                                    type="button"
                                    className="btn btn-white editable-cancel date_close-2 btn-sm"
                                  >
                                    <i className="mdi mdi-close text-danger "></i>
                                  </button>
                                </div>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                      {/* <Col
                        md="2"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {window.location.search && (
                          <Link
                            to="/quotations"
                            className="btn btn-light btn-sm"
                          >
                            See All
                            <i className="bx bx-right "></i>
                          </Link>
                        )}
                      </Col> */}
                    </Row>

                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                            <p>
                              {quotation?.results?.length === 0 && " No data"}
                            </p>
                          </Col>
                        </Row>
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
        {QDetails && (
          <Col lg={4}>
            <QuotationDetails quotationId={QDetails} />
          </Col>
        )}
      </Row>
    </React.Fragment>
  )
}

export default Quotation
