import React, { useState } from "react"
import { Badge, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { map } from "lodash"

function CalcForm({ fields, type, unit }) {
  const [values, setValues] = useState({})

  const handleCalculate = ({ target: { name, value } }) => {
    setValues(values => {
      return { ...values, [name]: value }
    })
  }

  const length = values.length ? values.length : 1
  const width = values.width ? values.width : 1
  const thickness = values.thickness ? values.thickness : 1
  const quantity = values.quantity ? values.quantity : 0
  const diameter = values.diameter ? values.diameter : 1
  const density = 7.88 * 1000

  //L/1000 * W/1000 * T * S
  // specific material density (Steel = 7.85)
  const sheetWeight = length * width * thickness * 7.85
  const totalSheetWeight = sheetWeight * quantity

  // L * (D - T) * T
  // π × [(D/2)² - (D/2 - T)²] × L × density of steel
  // Weight (in kilograms) = π × [(diameter/2)² - (diameter/2 - thickness)²] × length × (density)
  //pipe

  const pipeWeight =
    Math.PI *
    ((Math.pow(diameter / 2, 2) - Math.pow(diameter / 2 - thickness, 2)) *
      length *
      density)
  const totalPipeWeight = pipeWeight * quantity

  //tube [ { B - ( 2 × T )} × T × 2 nos]
  // Weight = Side1 x Side2 x Length x Density
  const tubeWeight = values?.Side1 * values?.Side2 * length * density
  const totalTubeWeight = tubeWeight * quantity

  const handelWeight = () => {
    if (type === "sheet") {
      return sheetWeight
    }
    if (type === "pipe") {
      return pipeWeight
    }
    if (type === "square tube") {
      return tubeWeight
    }
  }

  const handelTotalWeight = () => {
    if (type === "sheet") {
      return totalSheetWeight
    }
    if (type === "pipe") {
      return totalPipeWeight
    }
    if (type === "square tube") {
      return totalTubeWeight
    }
  }

  return (
    <>
      <form className="form-horizontal mt-1">
        <Row className="col-12 p-0 m-0">
          <p>Enter All Values In ({unit}) </p>
          {map(fields, (field, key) => (
            <Col lg={6} md={3} key={key}>
              <div className="mb-3">
                <label htmlFor={field}>
                  {field?.charAt(0).toUpperCase() + field?.slice(1)}
                </label>
                <input
                  name={field}
                  id={field}
                  className="form-control"
                  // placeholder={field?.charAt(0).toUpperCase() + field?.slice(1)}
                  type="number"
                  required
                  onChange={e => handleCalculate(e)}
                />
              </div>
            </Col>
          ))}

          <Col lg={12} className="p-0 text-end">
            <p className="mx-3">Density : {density} kg/m³</p>
            <h6 className="mt-1 p-2">
              Single piece weight:{" "}
              <Badge className="p-2 mx-2 font-size-14 badge-soft-success">
                {handelWeight()?.toFixed(2)} kg
              </Badge>
            </h6>
            <h6 className="mt-1 p-2">
              Total weight:{" "}
              <Badge className="p-2 mx-2 font-size-14 badge-soft-success">
                {handelTotalWeight()?.toFixed(2)} kg
              </Badge>
            </h6>
          </Col>
        </Row>
      </form>
    </>
  )
}

export default CalcForm

CalcForm.propTypes = {
  fields: PropTypes.array,
  type: PropTypes.string,
  unit: PropTypes.string,
}

// const pipeWeight =
// (values.length ? values.length : 1) *
// ((diameter) -
//   (thickness)) *
// (thickness) *
// (values.quantity ? 1 : 0)
