/* PRODUCTS */
export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL"

/* PRODUCTS DETAIL*/
export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

/**
 * add PRODUCT
 */
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS"
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL"

// clear state

export const CLEAR_PRODUCT_DETAIL = "CLEAR_PRODUCT_DETAIL"

/**
 * Edit PRODUCT
 */
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

/**
 * Delete PRODUCT
 */
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"

/**
 * add OTHERCOST
 */
export const CREATE_OTHERCOST = "CREATE_OTHERCOST"
export const CREATE_OTHERCOST_SUCCESS = "CREATE_OTHERCOST_SUCCESS"
export const CREATE_OTHERCOST_FAIL = "CREATE_OTHERCOST_FAIL"

/**
 * Delete OTHERCOST
 */
export const DELETE_OTHERCOST = "DELETE_OTHERCOST"
export const DELETE_OTHERCOST_SUCCESS = "DELETE_OTHERCOST_SUCCESS"
export const DELETE_OTHERCOST_FAIL = "DELETE_OTHERCOST_FAIL"

// rawmateril
export const GET_RAWMATERIALS = "GET_RAWMATERIALS"
export const GET_RAWMATERIALS_SUCCESS = "GET_RAWMATERIALS_SUCCESS"
export const GET_RAWMATERIALS_FAIL = "GET_RAWMATERIALS_FAIL"
/**
 * add RAWMATERIAL
 */
export const CREATE_RAWMATERIAL = "CREATE_RAWMATERIAL"
export const CREATE_RAWMATERIAL_SUCCESS = "CREATE_RAWMATERIAL_SUCCESS"
export const CREATE_RAWMATERIAL_FAIL = "CREATE_RAWMATERIAL_FAIL"

/**
 * Delete RAWMATERIAL
 */
export const DELETE_RAWMATERIAL = "DELETE_RAWMATERIAL"
export const DELETE_RAWMATERIAL_SUCCESS = "DELETE_RAWMATERIAL_SUCCESS"
export const DELETE_RAWMATERIAL_FAIL = "DELETE_RAWMATERIAL_FAIL"

// rawmateril
export const GET_PRODUCTDETAILS = "GET_PRODUCTDETAILS"
export const GET_PRODUCTDETAILS_SUCCESS = "GET_PRODUCTDETAILS_SUCCESS"
export const GET_PRODUCTDETAILS_FAIL = "GET_PRODUCTDETAILS_FAIL"
/**
 * add PRODUCTDETAIL
 */
export const CREATE_PRODUCTDETAIL = "CREATE_PRODUCTDETAIL"
export const CREATE_PRODUCTDETAIL_SUCCESS = "CREATE_PRODUCTDETAIL_SUCCESS"
export const CREATE_PRODUCTDETAIL_FAIL = "CREATE_PRODUCTDETAIL_FAIL"

/**
 * Delete PRODUCTDETAIL
 */
export const DELETE_PRODUCTDETAIL = "DELETE_PRODUCTDETAIL"
export const DELETE_PRODUCTDETAIL_SUCCESS = "DELETE_PRODUCTDETAIL_SUCCESS"
export const DELETE_PRODUCTDETAIL_FAIL = "DELETE_PRODUCTDETAIL_FAIL"

// Finished product

export const GET_FINISHEDPRODUCT = "GET_FINISHEDPRODUCT"
export const GET_FINISHEDPRODUCT_SUCCESS = "GET_FINISHEDPRODUCT_SUCCESS"
export const GET_FINISHEDPRODUCT_FAIL = "GET_FINISHEDPRODUCT_FAIL"

export const CREATE_FINISHEDPRODUCT = "CREATE_FINISHEDPRODUCT"
export const CREATE_FINISHEDPRODUCT_SUCCESS = "CREATE_FINISHEDPRODUCT_SUCCESS"
export const CREATE_FINISHEDPRODUCT_FAIL = "CREATE_FINISHEDPRODUCT_FAIL"

export const FINISHEDDETAILS = "FINISHEDDETAILS"
export const FINISHEDDETAILS_SUCCESS = "FINISHEDDETAILS_SUCCESS"
export const FINISHEDDETAILS_FAIL = "FINISHEDDETAILS_FAIL"
