/* SALESMANS */
export const GET_SALES_HEADS = "GET_SALES_HEADS"
export const GET_SALES_HEADS_SUCCESS = "GET_SALES_HEADS_SUCCESS"
export const GET_SALES_HEADS_FAIL = "GET_SALES_HEADS_FAIL"

/* SALES_HEADS DETAIL*/
export const GET_SALES_HEAD_DETAIL = "GET_SALES_HEAD_DETAIL"
export const GET_SALES_HEAD_DETAIL_SUCCESS = "GET_SALES_HEAD_DETAIL_SUCCESS"
export const GET_SALES_HEAD_DETAIL_FAIL = "GET_SALES_HEAD_DETAIL_FAIL"

/**
 * add SALES_HEAD
 */
export const CREATE_SALES_HEAD = "CREATE_SALES_HEAD"
export const CREATE_SALES_HEAD_SUCCESS = "CREATE_SALES_HEAD_SUCCESS"
export const CREATE_SALES_HEAD_FAIL = "CREATE_SALES_HEAD_FAIL"

/**
 * Edit SALES_HEAD
 */
export const UPDATE_SALES_HEAD = "UPDATE_SALES_HEAD"
export const UPDATE_SALES_HEAD_SUCCESS = "UPDATE_SALES_HEAD_SUCCESS"
export const UPDATE_SALES_HEAD_FAIL = "UPDATE_SALES_HEAD_FAIL"

/**
 * Delete SALES_HEAD
 */
export const DELETE_SALES_HEAD = "DELETE_SALES_HEAD"
export const DELETE_SALES_HEAD_SUCCESS = "DELETE_SALES_HEAD_SUCCESS"
export const DELETE_SALES_HEAD_FAIL = "DELETE_SALES_HEAD_FAIL"
