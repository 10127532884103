import {
  GET_SALESMANS_SUCCESS,
  GET_SALESMANS_FAIL,
  GET_SALESMAN_DETAIL_SUCCESS,
  GET_SALESMAN_DETAIL_FAIL,
  CREATE_SALESMAN_SUCCESS,
  CREATE_SALESMAN_FAIL,
  UPDATE_SALESMAN_SUCCESS,
  UPDATE_SALESMAN_FAIL,
  DELETE_SALESMAN_SUCCESS,
  DELETE_SALESMAN_FAIL,
  GET_SALESMANS,
  GET_SALESMAN_DETAIL,
  UPDATE_SALESMAN,
  CREATE_SALESMAN,
  DELETE_SALESMAN,
  GET_SALES_HEADS,
  UPDATE_SALES_HEAD,
  CREATE_SALES_HEAD,
  DELETE_SALES_HEAD,
  GET_SALES_HEAD_DETAIL,
  GET_SALES_HEADS_SUCCESS,
  GET_SALES_HEADS_FAIL,
  CREATE_SALES_HEAD_SUCCESS,
  CREATE_SALES_HEAD_FAIL,
  GET_SALES_HEAD_DETAIL_SUCCESS,
  GET_SALES_HEAD_DETAIL_FAIL,
  UPDATE_SALES_HEAD_SUCCESS,
  UPDATE_SALES_HEAD_FAIL,
  DELETE_SALES_HEAD_SUCCESS,
  DELETE_SALES_HEAD_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  salesHeads: [],
  salesHeadDetail: {
    location: "",
    profit: "",
    account: {
      email: "",
      username: "",
      phone: "",
      first_name: "",
      last_name: "",
    },
    id: "",
  },
  error: {},
  loading: false,
  detailLoading: false,
  // createsalesmanerror: "",
}

const SalesHeads = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SALES_HEADS:
    case UPDATE_SALES_HEAD:
    case CREATE_SALES_HEAD:
    case DELETE_SALES_HEAD:
      return {
        ...state,
        loading: true,
      }
    case GET_SALES_HEAD_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_SALES_HEADS_SUCCESS:
      return {
        ...state,
        salesHeads: action.payload,
        loading: false,
      }

    case GET_SALES_HEADS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_SALES_HEAD_SUCCESS:
      return {
        ...state,
        salesHeads: action.payload,
        loading: false,
      }

    case CREATE_SALES_HEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SALES_HEAD_DETAIL_SUCCESS:
      return {
        ...state,
        salesHeadDetail: action.payload,
        loading: false,
        detailLoading: false,
        error: {},
      }

    case GET_SALES_HEAD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_SALES_HEAD_SUCCESS:
      return {
        ...state,
        error: {},
        salesHeadDetail: action.payload,
        loading: false,
      }

    case UPDATE_SALES_HEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_SALES_HEAD_SUCCESS:
      console.log(state?.salesHeads)
      return {
        ...state,
        salesHeads: state?.salesHeads?.filter(
          salesman => salesman.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_SALES_HEAD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default SalesHeads
