import React, { useEffect, useState } from "react"
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getProducts, getQProductPrice, updateQuatation } from "store/actions"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

function EditModal({ show, onCloseClick, data, client }) {
  const [qty, setQty] = useState("")
  const dispatch = useDispatch()
  const params = useParams()
  console.log(data)
  const [searchText, setSearchText] = useState("")
  const [selectedProductId, setSelectedProductId] = useState([])
  const [selectedProduct, setSelectedProduct] = useState("Search a product")

  const [percentage, setPercentage] = useState(0)
  const [percentageLast, setPercentageLast] = useState()

  const {
    loading,

    products,
    QProductPrice,
  } = useSelector(state => ({
    loading: state.StoreItems.loading,
    products: state.Products.products,
    QProductPrice: state.Orders.QProductPrice,
  }))

  const onSubmitDatas = e => {
    const datas = {
      client: client,
      quotationitem: [
        {
          qty: qty,
          product: selectedProductId,
          profit: percentageLast,
          qty: qty,
          price: totalPriceCal,
        },
      ],
    }
    dispatch(updateQuatation(datas, "", params?.id, "", onCloseClick))
    console.log(datas)
  }
  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  useEffect(() => {
    dispatch(getProducts(searchText))
  }, [dispatch, searchText])

  function handlerFinalValue(event) {
    dispatch(getQProductPrice(event.value))
    // setProductId(event.value)
    setSelectedProduct(event.label)
    setSelectedProductId(event.value)
  }

  const optionGroup1 = [
    {
      options: products?.results?.map((result, index) => ({
        key: index,
        label: result?.name,
        value: result?.id,
      })),
    },
  ]
  const ProductPrice = parseInt(data?.cost)
  // // const totalPriceCalc = (ProductPrice * percentage) / 100 + ProductPrice
  // useEffect(() => {
  //   setTotal(totalPriceCalc)
  // }, [totalPriceCalc, QProductPrice])

  useEffect(() => {
    setPercentageLast(data?.profit)
  }, [data])

  console.log(data)

  const [total, setTotal] = useState()

  const [totalPriceCal, setTotalPriceCal] = useState()
  const handleTotal = e => {
    if (e) {
      setTotal(e.target.value * qty)
    } else {
      setTotal(totalPriceCal)
    }
  }
  const percentages = QProductPrice?.profit

  useEffect(() => {
    setQty(data?.qty)
    setSelectedProductId(data?.product)
    setSelectedProduct(data?.product_name)
    setPercentage(data?.profit)
  }, [data])

  const handleCalculate = value => {
    setPercentageLast(value)
  }

  useEffect(() => {
    setTotalPriceCal(data?.cost)
  }, [data])

  const totalPrice = (ProductPrice * percentageLast) / 100
  const qtyMultiAmount = totalPrice * qty

  console.log(ProductPrice)
  console.log(percentageLast)
  console.log(qty)

  useEffect(() => {
    setTotalPriceCal(qtyMultiAmount)
  }, [qtyMultiAmount])

  console.log(totalPriceCal)
  console.log(qtyMultiAmount)

  const ModalStyle = {
    width: 700,
    minWidth: 700,
  }
  const ModalBodyStyle = {
    minHeight: 500,
  }

  const finalAmount = data?.mrp * qty

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      style={ModalStyle}
    >
      <div className="d-flex align-items-center justify-content-between p-3">
        <div className="h5">Edit Quotation Item</div>
        <div>
          <i
            className="mdi mdi-close text-danger font-size-18"
            onClick={onCloseClick}
          />
        </div>
      </div>
      <ModalBody className="py-3 px-3">
        <div>
          <Row>
            <Col lg={6} md={6} sm={12} className="mb-3">
              <FormGroup className="mb-3">
                <Label>Products</Label>
                <div className="col-md-12"></div>
                <div className="ajax-select mt-1 mb-3 mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleEnters}
                    value={selectedProductId}
                    placeholder={selectedProduct}
                    onChange={handlerFinalValue}
                    options={optionGroup1}
                    classNamePrefix="select2-selection"
                    isLoading={true}
                    required="required"
                  />
                </div>

                {/* {QProductPrice?.cost && (
                  <span className="mt-2 text-muted">
                    MRP :
                    <Badge
                      className={"font-size-14 p-2 mx-3 badge-soft-success"}
                      pill
                    >
                      {QProductPrice?.cost}
                    </Badge>
                  </span>
                )} */}
              </FormGroup>
            </Col>
            <Col lg={6} md={3} sm={12} className="">
              <label htmlFor="profit-percentage">MRP</label>
              <input
                type="number"
                className="form-control mt-1 mt-lg-0"
                id="profit-percentage"
                required
                value={data?.mrp}
                readOnly
                // defaultValue={percentages}
                // onChange={e => handleCalculate(e.target.value)}
              />
            </Col>
            <Col lg={6} md={3} sm={12} className="">
              <label htmlFor="quantity">Quantity</label>
              <input
                type="number"
                className="form-control mt-1 mt-lg-0"
                id="qty"
                required
                value={qty}
                onChange={e => setQty(e.target.value)}
              />
            </Col>
            {/* {totalPriceCalc ? ( */}
            <Col lg={6} md={3} sm={12} className="">
              <label htmlFor="total-price">Total Price</label>
              <input
                type="number"
                className="form-control mt-1 mt-lg-0 text-warning"
                id="total-price"
                value={finalAmount}
                onChange={e => handleTotal(e)}
              />
            </Col>
            {/* ) : (
              <></>
            )} */}
            {/* {totalPriceCalc || total ? (
              <p
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                  padding: "0",
                }}
              >
                Total Price :
                <Badge
                  className={"font-size-14 p-2 mx-3 badge-soft-success"}
                  pill
                >
                  {total ? total : totalPriceCalc}
                </Badge>
              </p>
            ) : (
              <></>
            )} */}
            {/* <Col
              lg={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <input
                type="button"
                className={`btn btn-dark mr-lg-0 ${
                  disabledBtn() == false && "disabled"
                }`}
                value="Add to Quotation"
                onClick={() => onAddFormRow()}
                style={{
                  marginTop: "1rem",
                  pointerEvents: rawData.product == false && "none",
                }}
              />
            </Col> */}
          </Row>
        </div>

        <div className="d-flex align-items-center justify-content-end py-3">
          <Button
            onClick={e => onSubmitDatas(e)}
            color="success"
            className="px-5"
          >
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EditModal

EditModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  data: PropTypes.array,
  client: PropTypes.any,
}
