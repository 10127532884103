/* WORKSTAGES */
export const GET_WORKSTAGES = "GET_WORKSTAGES"
export const GET_WORKSTAGES_SUCCESS = "GET_WORKSTAGES_SUCCESS"
export const GET_WORKSTAGES_FAIL = "GET_WORKSTAGES_FAIL"

/* WORKSTAGES DETAIL*/
export const GET_WORKSTAGE_DETAIL = "GET_WORKSTAGE_DETAIL"
export const GET_WORKSTAGE_DETAIL_SUCCESS = "GET_WORKSTAGE_DETAIL_SUCCESS"
export const GET_WORKSTAGE_DETAIL_FAIL = "GET_WORKSTAGE_DETAIL_FAIL"

/**
 * add WORKSTAGE
 */
export const CREATE_WORKSTAGE = "CREATE_WORKSTAGE"
export const CREATE_WORKSTAGE_SUCCESS = "CREATE_WORKSTAGE_SUCCESS"
export const CREATE_WORKSTAGE_FAIL = "CREATE_WORKSTAGE_FAIL"

/**
 * Edit WORKSTAGE
 */
export const UPDATE_WORKSTAGE = "UPDATE_WORKSTAGE"
export const UPDATE_WORKSTAGE_SUCCESS = "UPDATE_WORKSTAGE_SUCCESS"
export const UPDATE_WORKSTAGE_FAIL = "UPDATE_WORKSTAGE_FAIL"

/**
 * Delete WORKSTAGE
 */
export const DELETE_WORKSTAGE = "DELETE_WORKSTAGE"
export const DELETE_WORKSTAGE_SUCCESS = "DELETE_WORKSTAGE_SUCCESS"
export const DELETE_WORKSTAGE_FAIL = "DELETE_WORKSTAGE_FAIL"
