import {
  GET_SALES_HEADS,
  GET_SALES_HEADS_SUCCESS,
  GET_SALES_HEADS_FAIL,
  GET_SALES_HEAD_DETAIL,
  GET_SALES_HEAD_DETAIL_SUCCESS,
  GET_SALES_HEAD_DETAIL_FAIL,
  CREATE_SALES_HEAD,
  CREATE_SALES_HEAD_SUCCESS,
  CREATE_SALES_HEAD_FAIL,
  UPDATE_SALES_HEAD,
  UPDATE_SALES_HEAD_SUCCESS,
  UPDATE_SALES_HEAD_FAIL,
  DELETE_SALES_HEAD,
  DELETE_SALES_HEAD_SUCCESS,
  DELETE_SALES_HEAD_FAIL,
} from "./actionTypes"

export const getSalesheads = (searchText, page) => ({
  type: GET_SALES_HEADS,
  payload: { searchText, page },
})

export const getSalesheadSuccess = salesheads => ({
  type: GET_SALES_HEADS_SUCCESS,
  payload: salesheads,
})
export const getSalesheadFail = error => ({
  type: GET_SALES_HEADS_FAIL,
  payload: error,
})

export const getSalesheadDetail = salesheadId => (
  console.log(salesheadId),
  {
    type: GET_SALES_HEAD_DETAIL,
    salesheadId,
  }
)

export const getSalesheadDetailSuccess = salesheadDetail => ({
  type: GET_SALES_HEAD_DETAIL_SUCCESS,
  payload: salesheadDetail,
})

export const getSalesheadDetailFail = error => ({
  type: GET_SALES_HEAD_DETAIL_FAIL,
  payload: error,
})

export const createSaleshead = (saleshead, history) => (
  console.log(saleshead),
  {
    type: CREATE_SALES_HEAD,
    payload: { saleshead, history },
  }
)

export const createSalesheadSuccess = saleshead => ({
  type: CREATE_SALES_HEAD_SUCCESS,
  payload: saleshead,
})

export const createSalesheadFail = error => ({
  type: CREATE_SALES_HEAD_FAIL,
  payload: error,
})

export const updateSaleshead = (saleshead, salesheadId) => ({
  type: UPDATE_SALES_HEAD,
  payload: { saleshead, salesheadId },
})

export const updateSalesheadSuccess = saleshead => ({
  type: UPDATE_SALES_HEAD_SUCCESS,
  payload: saleshead,
})

export const updateSalesheadFail = error => ({
  type: UPDATE_SALES_HEAD_FAIL,
  payload: error,
})

export const deleteSaleshead = (salesheadId, history) => ({
  type: DELETE_SALES_HEAD,
  payload: { salesheadId, history },
})

export const deleteSalesheadSuccess = saleshead => ({
  type: DELETE_SALES_HEAD_SUCCESS,
  payload: saleshead,
})

export const deleteSalesheadFail = error => ({
  type: DELETE_SALES_HEAD_FAIL,
  payload: error,
})
