import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SALES_HEADS,
  GET_SALES_HEAD_DETAIL,
  CREATE_SALES_HEAD,
  UPDATE_SALES_HEAD,
  DELETE_SALES_HEAD,
} from "./actionTypes"
import {
  getSalesheadSuccess,
  getSalesheadFail,
  getSalesheadDetailSuccess,
  getSalesheadDetailFail,
  createSalesheadFail,
  createSalesheadSuccess,
  updateSalesheadFail,
  updateSalesheadSuccess,
  deleteSalesheadSuccess,
  deleteSalesheadFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"

const getSalesHeadsAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(`/account/sales_head/?search=${searchText && searchText}`)
  } else {
    return get(`/account/sales_head/?page=${page ? page : 1}`)
  }
}
const getSalesHeadDetailsAPi = salesheadId => {
  console.log(salesheadId)
  return get(`/account/sales_head/${salesheadId?.salesheadId}/`)
}
const createSalesHeadApi = ({ saleshead }) => {
  console.log(saleshead)
  return post("/account/sales_head/", saleshead)
}
const updateSalesHeadApi = ({ saleshead, salesheadId }) => {
  return ApiPut(`/account/sales_head/${salesheadId}/`, saleshead)
}
const deleteSalesHeadApi = ({ salesheadId }) => {
  return del(`/account/sales_head/${salesheadId}/`)
}

function* fetchSaleHeads({ payload }) {
  console.log(payload)
  try {
    const response = yield call(getSalesHeadsAPi, payload)
    yield put(getSalesheadSuccess(response))
  } catch (error) {
    yield put(getSalesheadFail(error))
  }
}

function* fetchSalesHeadDetail(salesmanId) {
  console.log(salesmanId)

  try {
    const response = yield call(getSalesHeadDetailsAPi, salesmanId)
    yield put(getSalesheadDetailSuccess(response))
  } catch (error) {
    yield put(getSalesheadDetailFail(error))
  }
}
function* onCreateSalesHead({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createSalesHeadApi, payload)
    if (response?.error_message) {
      yield put(createSalesheadFail(response))
    } else {
      yield put(createSalesheadSuccess(response))
      payload.history.push("/salesheads")
      doneNotification()
    }
  } catch (error) {
    yield put(createSalesheadFail(error))
    errorNotification()
  }
}

function* onUpdateSalesHead({ payload }) {
  try {
    const response = yield call(updateSalesHeadApi, payload)
    if (response?.error_message) {
      yield put(updateSalesheadFail(response))
    } else {
      yield put(updateSalesheadSuccess(response))
      doneNotification()
    }
  } catch (error) {
    yield put(updateSalesheadFail(error))
    errorNotification()
  }
}

function* onDeleteSalesHead({ payload }) {
  try {
    const response = yield call(deleteSalesHeadApi, payload)
    payload.history.push("salesheads")
    yield put(deleteSalesheadSuccess(response))
    doneNotification()
  } catch (error) {
    console.log(error)
    yield put(deleteSalesheadFail(error))
    errorNotification()
  }
}

function errorNotification() {
  Notification({
    type: "error",
    message: "Something Went Wrong",
    title: "Try Again",
  })
}

function doneNotification() {
  Notification({
    type: "success",
    message: "Done",
    title: "",
  })
}

function* salesHeadsSaga() {
  yield takeEvery(GET_SALES_HEADS, fetchSaleHeads)
  yield takeEvery(GET_SALES_HEAD_DETAIL, fetchSalesHeadDetail)
  yield takeEvery(CREATE_SALES_HEAD, onCreateSalesHead)
  yield takeEvery(UPDATE_SALES_HEAD, onUpdateSalesHead)
  yield takeEvery(DELETE_SALES_HEAD, onDeleteSalesHead)
}

export default salesHeadsSaga
