/* GEN_MANAGERS */
export const GET_GEN_MANAGERS = "GET_GEN_MANAGERS"
export const GET_GEN_MANAGERS_SUCCESS = "GET_GEN_MANAGERS_SUCCESS"
export const GET_GEN_MANAGERS_FAIL = "GET_GEN_MANAGERS_FAIL"

/* GEN_MANAGERS DETAIL*/
export const GET_GEN_MANAGER_DETAIL = "GET_GEN_MANAGER_DETAIL"
export const GET_GEN_MANAGER_DETAIL_SUCCESS = "GET_GEN_MANAGER_DETAIL_SUCCESS"
export const GET_GEN_MANAGER_DETAIL_FAIL = "GET_GEN_MANAGER_DETAIL_FAIL"

/**
 * add GEN_MANAGER
 */
export const CREATE_GEN_MANAGER = "CREATE_GEN_MANAGER"
export const CREATE_GEN_MANAGER_SUCCESS = "CREATE_GEN_MANAGER_SUCCESS"
export const CREATE_GEN_MANAGER_FAIL = "CREATE_GEN_MANAGER_FAIL"

/**
 * Edit GEN_MANAGER
 */
export const UPDATE_GEN_MANAGER = "UPDATE_GEN_MANAGER"
export const UPDATE_GEN_MANAGER_SUCCESS = "UPDATE_GEN_MANAGER_SUCCESS"
export const UPDATE_GEN_MANAGER_FAIL = "UPDATE_GEN_MANAGER_FAIL"

/**
 * Delete GEN_MANAGER
 */
export const DELETE_GEN_MANAGER = "DELETE_GEN_MANAGER"
export const DELETE_GEN_MANAGER_SUCCESS = "DELETE_GEN_MANAGER_SUCCESS"
export const DELETE_GEN_MANAGER_FAIL = "DELETE_GEN_MANAGER_FAIL"
