/* FINISHEDPRODCHART */
export const GET_MONTHLY_CHART = "GET_MONTHLY_CHART"
export const GET_MONTHLY_CHART_SUCCESS = "GET_MONTHLY_CHART_SUCCESS"
export const GET_MONTHLY_CHART_FAIL = "GET_MONTHLY_CHART_FAIL"

export const GET_YEARLY_CHART = "GET_YEARLY_CHART"
export const GET_YEARLY_CHART_SUCCESS = "GET_YEARLY_CHART_SUCCESS"
export const GET_YEARLY_CHART_FAIL = "GET_YEARLY_CHART_FAIL"

export const GET_DASHBOARDDATA = "GET_DASHBOARDDATA"
export const GET_DASHBOARDDATA_SUCCESS = "GET_DASHBOARDDATA_SUCCESS"
export const GET_DASHBOARDDATA_FAIL = "GET_DASHBOARDDATA_FAIL"

export const GET_STOCKREPORT = "GET_STOCKREPORT"
export const GET_STOCKREPORT_SUCCESS = "GET_STOCKREPORT_SUCCESS"
export const GET_STOCKREPORT_FAIL = "GET_STOCKREPORT_FAIL"
