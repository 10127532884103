import React, { useEffect } from "react"
import {
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { filter, map } from "lodash"
import { Markup } from "interweave"

import Breadcrumb from "../Common/Breadcrumb"
import noImg from "assets/images/product.jpeg"

//images
import logo from "../../assets/images/logo/Indtech.png"
import { getQuotationDetail } from "store/actions"
import { useParams } from "react-router-dom"

const PDFGenerator = () => {
  const params = useParams()
  const dispatch = useDispatch()

  //redux state
  const { quotationDetails } = useSelector(state => ({
    quotationCurd: state.Orders.quotationCurd,
    AllQProducts: state.Orders.AllQProducts,
    QuotationItems: state.Orders.quotation.quotationitem,
    quotationDetails: state.Orders.quotationDetails,
  }))

  useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 2000)
  }, [])

  useEffect(() => {
    dispatch(getQuotationDetail(params?.id))
  }, [])

  const letterHead = quotationDetails?.letter_head
  const bank_details = quotationDetails?.bank_details
  const trems_and_conditions = quotationDetails?.terms
  const warrenty = quotationDetails?.warrenty

  const download = () => {
    window.print()
  }

  const difference = quotationDetails?.mrp_total - quotationDetails?.total

  const discountedAmount =
    quotationDetails?.mrp_total - quotationDetails?.discount

  return (
    <>
      <MetaTags>
        <title> &nbsp; </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumb title="Quotation" breadcrumbItem="Quotation PDF" />
        <Card className="p-4">
          <div
            type="button"
            className="btn btn-outline-light d-flex display-none"
            style={{
              marginLeft: "auto",
              alignItems: "center",
              width: "fit-content",
              border: "1px solid #cccc",
            }}
            onClick={download}
          >
            PDF
            <i className="mdi mdi-download d-block font-size-16 mx-1"></i>
          </div>
          <CardBody>
            <CardTitle>
              <img
                src={logo}
                alt="indetch-logo"
                height={80}
                className=" mx-auto"
              />
            </CardTitle>

            {/* <CardTitle
              style={{ textAlign: "center" }}
              className="mb-0 mt-4 font-size-18"
            >
              Quotation
            </CardTitle> */}
            <CardSubtitle className="mb-0 mt-0">
              <Row style={{ alignItems: "end" }}>
                <div className="d-flex align-item-center justify-content-between">
                  <div>
                    <div className="mt-2">
                      <h5>To,</h5>
                      <h6 style={{ paddingLeft: "1rem" }}>
                        {quotationDetails?.client_name}
                      </h6>
                      <h6 style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        {quotationDetails?.client_address}
                      </h6>
                    </div>
                  </div>
                  <div className="">
                    <h6>
                      Date :
                      {moment(quotationDetails?.date_added).format(
                        "DD/MM/YYYY"
                      )}
                    </h6>
                    <div className="d-flex align-items-center">
                      <h6>
                        <span style={{ fontWeight: 600 }}>
                          Quotation No :{" "}
                          {`${quotationDetails && quotationDetails?.auto_id}`}
                        </span>
                      </h6>
                    </div>
                    <small>Point Of Contact</small>
                    <div
                      style={{
                        border: "0.1px ridge  rgb(209, 202, 202)",
                        padding: 15,
                        borderRadius: 5,
                      }}
                    >
                      <span className="font-size-13">
                        Name &nbsp;: &nbsp;
                        <span className="text-black">
                          &nbsp; {quotationDetails?.contact_person}
                        </span>{" "}
                      </span>
                      <br />
                      <span className="font-size-13">
                        {" "}
                        Phone &nbsp;: &nbsp;
                        <span className="text-black">
                          {quotationDetails?.contact_person_phone}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </Row>
            </CardSubtitle>

            <CardSubtitle className="">
              <Markup content={letterHead} />
            </CardSubtitle>

            <div className="table-responsive">
              <Table className="table mb-0 table-bordered pdf-bordered tdf">
                <thead>
                  <tr>
                    <th style={{ maxWidth: 45, fontSize: 12 }} className="">
                      SL.NO
                    </th>
                    <th style={{ fontSize: 12 }}>PRODUCT </th>
                    <th style={{ fontSize: 12 }}>PHOTO</th>
                    <th style={{ maxWidth: 100,minWidth:40, fontSize: 12 }}>PRICE</th>
                    <th style={{ maxWidth: 50, fontSize: 12 }}>QTY</th>
                    <th style={{ fontSize: 12, maxWidth: "auto" }} className="">
                      AMOUNT
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quotationDetails?.quotationitem && (
                    <>
                      {map(
                        quotationDetails?.quotationitem,
                        (quotation, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              <h3 className="text-info font-size-16 mb-2">
                                {quotation?.product_code}&nbsp;-&nbsp;
                                {quotation?.product_name}
                              </h3>

                              {/* <small
                                className="mb-4 font-size-11 text-black-head"
                                style={{ fontWeight: 500 }}
                              >
                                {quotation?.product_name}
                              </small> */}

                              {/* <img
                                src={quotation?.product.image}
                                alt="product"
                                id="expandedImg1"
                                className="d-block mb-5 pb-4"
                                style={{ maxWidth: "300px" }}
                              /> */}

                              <ul>
                                {map(
                                  quotation?.product_detail?.filter(
                                    item => item.is_description == true
                                  ),
                                  (des, deskey) => (
                                    <li key={deskey} className="pb-2">
                                      {/* <div className="d-flex ">
                                        <h6
                                          className=""
                                          style={{ fontSize: "14px" }}
                                        >
                                          {des.title}
                                        </h6>
                                        <p>{des.detail}</p>{" "}
                                      </div> */}
                                      {/* <h6
                                          className=""
                                          style={{ fontSize: "14px" }}
                                        > */}
                                      <span className="h6">
                                        {des.title} : &nbsp;
                                      </span>
                                      <span className="">{des.detail}</span>
                                      {/* Assuming 'detail' is text, so using <p> instead of another <h6> */}
                                    </li>
                                  )
                                )}
                              </ul>

                              <div className="mt-4">
                                {map(
                                  quotation?.product_detail?.filter(
                                    item => item.is_description == false
                                  ),
                                  (des2, deskey2) => (
                                    <div key={deskey2} className="pb-3">
                                      <small
                                        className="text-black-head"
                                        style={{ fontWeight: 500 }}
                                      >
                                        {des2.title}
                                      </small>
                                      <div>
                                        {" "}
                                        <small>{des2.detail}</small>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </td>
                            <td>
                              <img
                                src={
                                  quotation?.image_url
                                    ? quotation?.image_url
                                    : ""
                                }
                                alt=""
                                id="expandedImg1"
                                className=" mb-5 pb-4"
                                style={{
                                  maxWidth: "300px",
                                  height: 120,
                                  minWidth: 120,
                                  objectFit: "contain",
                                }}
                              />
                            </td>

                            <td>
                              <small className="text-black font-size-10">
                                {parseFloat(quotation?.mrp ||'---')}
                              </small>
                            </td>
                            <td>
                              <small className="text-black font-size-10">
                                {/* <i className="bx bx-rupee" /> */}
                                {quotation?.qty || '---'}
                              </small>
                            </td>
                            <td>
                              <small
                                className="text-black font-size-10"
                                style={{ fontWeight: 600 }}
                              >
                                {/* <i className="bx bx-rupee" /> */}
                                {quotation?.qty * quotation?.mrp || '---'}
                              </small>
                            </td>
                          </tr>
                        )
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </div>

            <div className="d-flex align-items-center justify-content-end pt-4">
              <div className="" style={{ width: 300 }}>
                <div className="row">
                  <div className="col-6">
                    <small className="text-black font-size-12">
                      Total Amount &nbsp;:&nbsp;
                    </small>
                  </div>
                  <div className="col-6">
                    <small className="text-black font-size-12">
                      {quotationDetails?.mrp_total}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            {quotationDetails?.discount && (
              <div className="d-flex align-items-center justify-content-end py-1">
                <div className="" style={{ width: 300 }}>
                  <div className="row">
                    <div className="col-6">
                      <small className="text-black font-size-12">
                        Discount Amount &nbsp;:
                      </small>
                      &nbsp;
                    </div>
                    <div className="col-6">
                      {quotationDetails?.discount && (
                        <small className="text-black font-size-12">
                          {parseFloat(quotationDetails?.discount)} (
                          {parseFloat(quotationDetails?.discount_percentage || 0)}%)
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex align-items-center justify-content-end py-1">
              <div className="" style={{ width: 300 }}>
                <div className="row">
                  <div className="col-6">
                    <small
                      className="text-black font-size-12"
                      style={{ fontWeight: 500 }}
                    >
                      Net Amount&nbsp; : &nbsp;
                    </small>
                  </div>
                  <div className="col-6 ">
                    <small
                      className="text-black font-size-14"
                      style={{ fontWeight: 500 }}
                    >
                      {discountedAmount}
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 pt-1 pb-1 mt-1">
              <p className="mt-1 pt-1 mb-1 pb-1">
                {quotationDetails.letter_middle}
              </p>

              <h5 className="mb-2">TERMS AND CONDITIONS :</h5>
              <Markup content={trems_and_conditions} />
            </div>
            <h5 className="mb-2">Warranty clause : </h5>

            <Markup content={warrenty} />
            <div
              style={{
                // border: "1px solid #0000001f",
                padding: "2rem",
              }}
            >
              <h5 className="mb-3">Account Details :</h5>
              <Markup content={bank_details} />
            </div>

            <div style={{ width: "fit-content", marginLeft: "" }}>
              {/* <h5 className="mt-5 pt-3">Thanking you</h5> */}
              <h5 className="pb-5 mb-5">Authorized Signatory</h5>
              <h5>Indtech Healthcare Pvt Ltd</h5>
            </div>
            <div style={{ height: "100px" }}></div>
          </CardBody>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <Row className="">
              <Col
                className="col-6 "
                style={{
                  display: "flex",
                }}
              >
                <i
                  style={{
                    fontSize: "22px",
                    color: "#1495d3",
                    padding: "0 1rem",
                  }}
                  className="dripicons-location"
                ></i>
                <div>
                  <h6 className="">
                    {" "}
                    Plot No: B5, Industrial development plot, Paloor kotta,
                  </h6>
                  <p>
                    Palachode Post, Angadippuram via, Malappuram, Kerala 679338
                  </p>
                </div>
              </Col>
              <Col className="col-6">
                <h6>KOCHI | CALICUT | MALAPPURAM</h6>
                <p>www.indtechhc.com | info@indtechhc.com | +91 7594 822 822</p>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </>
  )
}

export default PDFGenerator
