/* SALESMANS */
export const GET_SALESMANS = "GET_SALESMANS"
export const GET_SALESMANS_SUCCESS = "GET_SALESMANS_SUCCESS"
export const GET_SALESMANS_FAIL = "GET_SALESMANS_FAIL"

/* SALESMANS DETAIL*/
export const GET_SALESMAN_DETAIL = "GET_SALESMAN_DETAIL"
export const GET_SALESMAN_DETAIL_SUCCESS = "GET_SALESMAN_DETAIL_SUCCESS"
export const GET_SALESMAN_DETAIL_FAIL = "GET_SALESMAN_DETAIL_FAIL"

/**
 * add SALESMAN
 */
export const CREATE_SALESMAN = "CREATE_SALESMAN"
export const CREATE_SALESMAN_SUCCESS = "CREATE_SALESMAN_SUCCESS"
export const CREATE_SALESMAN_FAIL = "CREATE_SALESMAN_FAIL"

/**
 * Edit SALESMAN
 */
export const UPDATE_SALESMAN = "UPDATE_SALESMAN"
export const UPDATE_SALESMAN_SUCCESS = "UPDATE_SALESMAN_SUCCESS"
export const UPDATE_SALESMAN_FAIL = "UPDATE_SALESMAN_FAIL"

/**
 * Delete SALESMAN
 */
export const DELETE_SALESMAN = "DELETE_SALESMAN"
export const DELETE_SALESMAN_SUCCESS = "DELETE_SALESMAN_SUCCESS"
export const DELETE_SALESMAN_FAIL = "DELETE_SALESMAN_FAIL"
